* {
	padding: 0;
	margin: 0;
	font-size: 1rem;
	text-align: left;
	font-family: roboto, Helvetica, Arial, sans-serif;
}

*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
	/*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);*/
	width: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--p);
	outline: 1px solid var(--l);
}
.DraftEditor-root {
	text-align: left;
}
:root {
	--l: #ffffff;
	--d: #000000;
	--p: #ff9900;
	--w: #f26500;
	--g: #3f3f3f;
	--lg: #d9d9d9;
	--radius: 8px;
	--border: 2px solid var(--p);
	--shadow: 1px 2px 3px #00000040;
	--transitionIn: 300ms ease-in;
	--transitionOut: 250ms ease-out;
}

.hidden {
	display: none !important;
}

.jfs {
	justify-content: flex-start !important;
}

.warn {
	color: var(--w) !important;
}

.wrap {
	flex-wrap: wrap;
}

.textwrap {
	word-wrap: wrap;
}

body {
	width: 100%;
	height: 100%;
	max-width: 100%;
	overflow-y: scroll;
	color: var(--d);
}

.App {
	max-width: 100%;
	padding: 0;
	min-height: 100vh;
	max-height: 100vh;
	width: 100%;
	min-width: 94%;
	color: var(--d);
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	overflow-x: hidden;
	background-color: var(--l);
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	color: inherit;
}

h1 {
	font-size: 2rem;
	max-width: 100%;
	padding: 10px;
	cursor: default;
	font-weight: 900;
}

h2 {
	font-size: 1.5rem;
	max-width: 100%;
	cursor: default;
	padding: 10px;
	font-weight: 900;
}

h3 {
	font-size: 1.2rem;
	max-width: 100%;
	cursor: default;
	padding: 5px;
	font-weight: 900;
}

span {
	font-weight: 400;
	max-width: 100%;
	cursor: default;
	padding: 5px;
}

p {
	font-weight: 400;
	max-width: 100%;
	cursor: default;
	padding: 5px;
	margin: 0;
}

label {
	font-size: 0.9rem;
	font-weight: 800;
	max-width: 100%;
	cursor: default;
	padding: 5px;
}

button {
	font-size: 1.1rem;
	padding: 0.1em 0.3em;
	margin: 0.65em;
	font-weight: 800;
	word-break: keep-all;
	text-transform: uppercase;
	border-radius: var(--radius);
	background-color: var(--p);
	color: var(--l);
	transition: var(--transitionIn);
	box-shadow: var(--shadow);
	border: 2px solid var(--p);
}

button:hover {
	cursor: pointer;
	border: var(--border);
	transition: var(--transitionOut);
	color: var(--p);
	background-color: var(--l);
}

input,
textarea,
select {
	border-radius: var(--radius);
	border: var(--lightBorder);
	max-width: 100%;
	margin: 5px 0;
	padding: 5px 10px;
	font-size: 1.1rem;
	font-weight: 800;
	text-align: left;
}

.rel {
	position: relative;
}

#header {
	position: sticky;
	z-index: 100000;
	top: 0;
	left: 0;
	padding: 0px;
	margin: 0;
	width: 100%;
	max-width: 100%;
	color: var(--l);
	background-color: var(--d);
	border-bottom: 2px solid var(--p);
	display: grid;
	grid-template-columns: 1fr 5fr;
}

#headgrid {
	/* grid-area: 1 / 1 / span 1 / span 2;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; */
	grid-area: 1 / 1 / span 1 / span 2;
	display: flex;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: space-between;
	align-items: flex-end;
}

#logo {
	grid-area: 1 / 2 / span 1 / span 1;
	padding: 10px;
	max-width: 100%;
	cursor: pointer;
}

#buttons {
	grid-area: 2 / 2 / span 1 / span 1;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

#dochead {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 9999;
	padding: 0;
	margin: 0;
	width: 100%;
	max-width: 100%;
	color: var(--l);
	background-color: var(--d);
	border-bottom: 2px solid var(--p);
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

#dochead #over {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: auto;
	width: 100%;
	padding: 5px;
}
#dochead #over h1 {
	font-size: 16px;
}
#doclet {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	padding: 10px;
	max-width: 100%;
	margin: 0;
	min-width: 95%;
	padding: 0;
}
#doclet button,
#doclet select,
#doclet option {
	font-size: 0.9rem;
}
.rdw-editor-toolbar {
	padding: 6px 5px 0;
	border-radius: 2px;
	border: 1px solid #f1f1f1;
	display: flex;
	justify-content: flex-start;
	background: #fff;
	flex-wrap: wrap;
	font-size: 15px;
	margin-bottom: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.DraftEditor-editorContainer {
	min-height: 10vh;
	cursor: text;
}
.closer {
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 15;
	font-size: 1.1rem;
	background-color: var(--p);
	border: 2px solid var(--l);
	padding: 2px 5px;
	border-radius: var(--radius);
	cursor: pointer;
	transition: var(--transitionIn);
}

.closer:hover {
	transition: var(--transitionOut);
	color: var(--g);
}
.selectedFilter {
	cursor: pointer;
	background-color: var(--d);
	color: var(--l);
}
.unselectedFilter {
	cursor: pointer;
}
.selectedFilter:hover {
	background-color: var(--p);
	color: var(--l);
}
.unselectedFilter:hover {
	background-color: var(--p);
	color: var(--l);
}
.b1 {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* overflow: hidden; */
	margin: 5px 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
}

.b2 {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	max-width: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
	flex-wrap: wrap;
}

.fifty .b1 {
	max-width: 40%;
	width: auto;
	overflow: hidden;
	padding: 10px;
	margin: 0;
}

.fading {
	transition: var(--transitionIn);
}

.fading:hover {
	transition: var(--transitionOut);
	opacity: 0.7;
	transform: scale(0.9, 0.9);
}

.c1 {
	display: grid;
	grid-template-columns: 1fr;
	max-width: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
	grid-gap: 10px;
}

.c2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	padding: 0;
	margin: 0;
	grid-gap: 10px;
}

.c3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 100%;
	width: 100%;
	padding: 0;
	overflow: hidden;
	margin: 0;
	grid-gap: 10px;
}

.c4 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	grid-gap: 10px;
	overflow: hidden;
}

.d1 {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: 1fr;
	grid-gap: 10px;
	max-width: 100%;
	padding: 10px;
	overflow-y: hidden;
}

.d2 {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: 1fr 1fr;
	grid-gap: 10px;
	max-width: 100%;
	padding: 10px;
	overflow-y: hidden;
}

.d3 {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: 1fr 1fr 1fr;
	grid-gap: 10px;
	max-width: 100%;
	padding: 10px;
	overflow-y: hidden;
}

select {
	font-weight: 600;
}

ul li p {
	text-align: left !important;
}

.jsa {
	justify-content: space-around;
}

.snackbar {
	visibility: hidden;
	min-width: 50px;
	background-color: var(--p);
	color: var(--l);
	text-align: center;
	padding: 5px 10px;
	position: fixed;
	z-index: 100000;
	right: 10px;
	bottom: 10px;
	font-size: 17px;
	border-radius: var(--radius);
}

.snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 350ms;
	animation: fadein 350ms;
}

.close {
	position: absolute;
	right: 0;
	top: 0;
	/* width: 40px; */
	/* height: 40px; */
	z-index: 9999;
	/* padding-left: 12px; */
}

ol,
ul {
	padding: 0;
	list-style-type: none;
}

#homepageList ul li {
	list-style-position: outside;
	margin-left: 1em;
}

.policylist {
	list-style-type: circle;
	list-style: inside;
	max-width: 100%;
	padding: 20px;
}

.policylist li {
	text-align: left;
	cursor: pointer;
	padding: 5px;
	width: auto;
	max-width: 100%;
	border-radius: var(--radius);
	transition: var(--transitionOut);
}

.policylist li:hover {
	background-color: var(--p);
	color: var(--l);
	transition: var(--transitionIn);
}

.rdw-editor-wrapper {
	max-width: 100%;
	width: 90%;
	padding: 10px 5%;
	padding-bottom: 20px;
	min-height: 70vh;
	max-height: 70vh;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
}

.rdw-editor-wrapper span {
	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;
	text-decoration: inherit;
	font-family: inherit;
	margin: inherit;
	padding: inherit;
}

/* .rdw-editor-wrapper h1 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper h2 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper h3 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper h4 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper h5 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  font-family: inherit;
  text-decoration: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper h6 {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
} */
/* .rdw-editor-wrapper p {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
}
.rdw-editor-wrapper li {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  font-family: inherit;
  margin: inherit;
  padding: inherit;
} */
.rdw-editor-toolbar {
	color: var(--p);
}

.rdw-editor-toolbar li {
	text-align: left;
}

.rdw-editor-toolbar button {
	background-color: var(--d);
	color: var(--l);
}

.rdw-image-modal-header-label-highlighted {
	border-bottom: var(--border) !important;
}

.rdw-image-modal-btn:disabled {
	background-color: var(--lg) !important;
	color: var(--l);
	text-shadow: var(--shadow);
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
	left: -27px !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
	right: -27px !important;
}
.top-0 {
	top: 0;
}
.sticky {
	position: sticky;
}
.flex {
	display: flex;
}
.z-50 {
	z-index: 50;
}
@media screen and (max-width: 1080px) {
	#doclet {
		grid-template-columns: 1fr;
	}
	.grid {
		grid-template-columns: 1fr 1fr;
	}

	#nav {
		display: none;
	}

	.c2 {
		grid-template-columns: 1fr;
	}

	.c3 {
		grid-template-columns: 1fr 1fr;
	}

	.c4 {
		grid-template-columns: 1fr 1fr;
	}

	#app {
		width: 100%;
		padding: 0;
	}
}

@media screen and (min-width: 1300px) {
	.c4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 650px) {
	#doclet {
		grid-template-columns: 1fr;
	}
	input,
	textarea,
	select {
		font-size: 1rem;
	}

	#mobNavOpen {
		font-size: 1rem;
	}

	.grid {
		grid-template-columns: 1fr;
	}

	.options {
		width: auto;
		padding: 10px;
		max-width: 33%;
		min-width: 200px;
		right: 0px;
		top: 0px;
		background-color: var(--p);
		color: var(--l);
		box-shadow: 1px 2px 3px #00000040;
		z-index: 4;
		border-radius: var(--radius);
		border-top-right-radius: 0;
		position: fixed;
	}

	.options #sortingMode .sortGrid button {
		background-color: var(--g) !important;
		color: var(--d);
	}

	.sortGrid button.activebutton {
		background-color: var(--l);
		color: var(--p);
	}

	.sortGrid button {
		color: var(--l);
	}

	#sorter {
		color: var(--l);
	}

	.fixedButton {
		bottom: 5px;
		right: 5px;
		z-index: 1;
		position: fixed;
	}

	input,
	textarea,
	select {
		font-size: 0.9rem;
	}

	.c3 {
		grid-template-columns: 1fr;
	}

	.c2 {
		grid-template-columns: 1fr;
	}

	.fifty .b1 {
		max-width: 100%;
		padding: 10px;
		margin: 0;
	}

	#nav #logo {
		width: 70%;
		max-width: 70%;
		overflow: hidden;
	}
	.checklist {
		grid-template-columns: 1fr;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slidedown {
	from {
		opacity: 0;
		transform: translateY(-20vh);
	}

	50% {
		opacity: 1;
	}

	to {
		transform: translateY(0);
	}
}

@keyframes slideup {
	from {
		opacity: 0;
		transform: translateY(20vh);
	}

	50% {
		opacity: 1;
	}

	to {
		transform: translateY(0);
	}
}

@-webkit-keyframes fadein {
	from {
		bottom: -60px;
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: -60px;
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		bottom: -60px;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		bottom: -60px;
		opacity: 0;
	}
}
.doc-title {
	padding: 2rem 5rem 2rem 5rem;
}
.search-filter {
	display: flex;
	justify-content: space-between;
	padding: 0.2rem 5rem;
	align-items: center;
	background-color: #efefef;
}
select#categories {
	border: 1px solid #d8d8d8;
	border-radius: 0;
	margin: 0px 5px;
	width: 160px;
	padding: 5px 5px;
	background: white;
	font-size: 12px;
	height: 30px;
}
input#searchkey {
	border: 1px solid #d8d8d8;
	border-radius: 0;
	width: 165px;
	padding: 5px 5px;
	background: white;
	font-size: 12px;
	height: 20px;
}
.policy-item {
	border: 1px solid #eceae5;
	margin: 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	overflow: hidden;
}
.policy-item .img {
	/* width: 25% !important; */
	width: 70px !important;
	flex-shrink: 0;
	display: block !important;
	overflow: hidden;
	text-align: center;
	background: #fef4f0;
}
.policy-item .img img {
	width: 47px;
	margin: 5px 0;
}
.policy-item span {
	padding: 8px 10px;
	width: 100% !important;
	display: block !important;
	overflow: hidden;
	font-size: 12px;
	cursor: pointer;
}
.policypageList {
	padding: 0 5rem;
}
.policy-item:hover {
	border: 1px solid #ff9900;
	/* transition: var(--transitionIn); */
	cursor: pointer;
}
.Pdf {
	width: 100%;
	height: 100vh;
}
.w-33 {
	width: 33.33%;
}

/*06-06-2023*/

.list-row .p-box {
	/* padding-left:10px; */
	/* padding-right:10px; */
}
.list-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
	width: 100%;
}

.list-row .p-box {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.333%;
	flex: 0 0 33.333%;
	max-width: 33.333%;
	overflow: hidden;
}
.list-inner {
	margin: auto;
	background-color: #fff;
}

.list-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 2rem 5rem;
	align-items: center;
	background-color: #fff;
}
.container-sm {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.row-sm {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col-sm-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.policy-item span {
	line-height: 1.4;
}
.w-100 {
	width: 100%;
}
.no-items {
	display: block;
	width: 100%;
	text-align: center;
}

.catserch {
	display: none;
}
input[type="file"] {
	height: 50px;
}
.loading {
	height: 85vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.all-document {
	/* display: flex;
	align-items: center;
	flex-direction: column; */
	display: flex;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
	width: 100%;
	justify-content: center;
}

.document h2 {
	font-size: 50px;
	text-align: center;
}

.document :hover {
	background-color: white;
	color: #f89901;
	border-radius: 15px;
}
.document {
	background-color: #f89901;
	color: white;
	padding: 5px;
	margin: 10px;
	border: 1px solid #f89901;
	min-width: 300px;
	border-radius: 15px;
}
.document h2 {
	cursor: pointer;
}

h1.omm-title {
	font-size: 60px;
}
.video-box {
	width: 100%;
	text-align: center;
	margin-top: 10px;
}

input#video {
	width: 78%;
	border: 1px solid;
	margin: 0 10px;
	border-radius: 0;
}

.pdf-box {
	width: 100%;
	text-align: center;
	display: block;
}
input.video-url {
	width: 90%;
	border: 1px solid;
	border-radius: 0;
}
.radio-box {
	display: flex;
	width: 100%;
	text-align: center;
	justify-content: center;
}

.radio-box label {
	padding: 10px;
	border: 1px solid;
	margin: 5px;
	width: 11%;
	text-align: center;
}

.radio-box label input[type="radio"] {
	margin: 0 10px 0 0;
}
.d-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.d-flex button {
	border-radius: 0 !important;
	padding: 0;
	margin: 0;
	border: none;
}
.d-flex button :hover {
	pointer-events: none;
}
img.icon {
	width: 34px;
	height: 34px;
}
button.active {
	background: white;
	color: #f89901;
}
.p-list {
	width: 100%;
}
.created-modified {
	display: flex;
	flex-direction: column;
}
@media screen and (min-width: 473px) {
	.catserch {
		display: none !important;
	}
}
@media screen and (max-width: 475px) {
	.radio-box label {
		width: 32% !important;
	}
	.d-none {
		display: none !important;
	}
	.document h2 {
		font-size: 30px;
		text-align: center;
	}

	.document {
		padding: 0;
		margin: 10px;
		border: 1px solid;
	}
	.view-toogle {
		display: flex;
		align-items: flex-end;
	}
	h1.omm-title {
		font-size: 30px;
	}
	.search-filter {
		display: block;
	}
	.search-filter .category label {
		display: block;
	}
	.search-filter select#categories,
	.search-filter input#searchkey {
		width: 100%;
		margin: 0;
	}
	.search-filter input#searchkey {
		box-sizing: border-box;
		height: 32px;
		margin-top: 6px;
		padding-left: 8px;
		margin-bottom: 3px;
	}
	.catserch {
		display: none !important;
	}
}
@media screen and (max-width: 767px) {
	#headgrid {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		align-items: center;
		flex-direction: column;
	}
	.w-33 {
		width: 100%;
	}
	div#logo {
		width: 51%;
		margin-top: 8px;
		padding-bottom: 0;
	}
	#buttons {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	button {
		font-size: 10px;
		border-radius: 0;
		text-align: center;
	}
	.list-row .p-box {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.list-wrapper {
		width: auto;
		padding: 1.5rem 2rem;
	}

	.policy-item {
		margin: 10px 0;
	}
	.list-row {
		width: auto;
	}
	.doc-title,
	.search-filter {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.doc-title h1 {
		padding: 10px 0;
	}
	.search-filter {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.catserch {
		display: block;
		opacity: 0;
		font-size: 7px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.list-row .p-box {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.policy-item span {
		padding: 5px 10px;
	}
}
